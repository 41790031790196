import { RouteData } from '@wearejh/m2-pwa-engine/lib/utils/getKnownRoute';

function beginsWith(url: string, segment: string) {
    if (url === segment) {
        return true;
    }
    if (url.split('/')[1] === segment.slice(1)) {
        return true;
    }
    return false;
}

export const knownRoutes: RouteData[] = [
    {
        test: (url) => {
            return url === '/';
        },
        value: { type: 'HOME', id: 2, __typename: 'EntityUrl' },
    },
    {
        test: '/checkout',
        value: { type: 'CHECKOUT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/basket',
        value: { type: 'BASKET', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/order-tracking',
        value: { type: 'ORDER_TRACKING', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/shared-basket',
        value: { type: 'SHARED_BASKET', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: (url) => beginsWith(url, '/customer'),
        value: { type: 'CUSTOMER', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/offline',
        value: { type: 'OFFLINE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/outdated',
        value: { type: 'OUTDATED', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/_reset',
        value: { type: 'RESET', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: (url) => beginsWith(url, '/customisation'),
        value: { type: 'CUSTOMISATION', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/catalogsearch',
        value: { type: 'CATALOG_SEARCH', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/about-us',
        value: { type: 'CMS_ABOUT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/contact-us',
        value: { type: 'CMS_CONTACT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/delivery',
        value: { type: 'CMS_DELIVERY', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/faqs',
        value: { type: 'CMS_FAQ', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/artwork-guidelines',
        value: { type: 'CMS_ARTWORK', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/artwork/approve',
        value: { type: 'ARTWORK_APPROVE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/customer-service/login',
        value: { type: 'CUSTOMER_SERVICE_PORTAL', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/bulk-and-corporate',
        value: { type: 'BULK_AND_CORPORATE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: (url) => beginsWith(url, '/personalised-workwear1'),
        value: { type: 'PPC1', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: (url) => beginsWith(url, '/personalised-workwear2'),
        value: { type: 'PPC2', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_bundle',
        value: { type: 'BUNDLE_PRODUCT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_wearer_bundle',
        value: { type: 'WEARER_BUNDLE_PRODUCT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_config',
        value: { type: 'CONFIGURABLE_PRODUCT', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_category_landing',
        value: { type: 'CATEGORY_LANDING_PAGE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/__test_cms',
        value: { type: 'CMS_PAGE', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/blog',
        value: { type: 'BLOG', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/bulk-printed-tshirts',
        value: { type: 'TSHIRTS', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/free-returns',
        value: { type: 'FREE_RETURNS', id: 0, __typename: 'EntityUrl' },
    },
    {
        test: '/customisation-prices',
        value: { type: 'PRICING', id: 0, __typename: 'EntityUrl' },
    },
];
