import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';
import { aggregations, aggregationsVariables } from 'src/queries/__generated__/aggregations';
import { useDeps } from 'src/hooks/useDeps';

import categoryQuery from '../../queries/aggregations.graphql';

import {
    Aggregation,
    aggregationsToMap,
    fromAggregationsQuery,
    MappedAggregations,
    TagsAggregation,
} from './utils/cleanAggregations';

interface AggregationQueryContextType {
    aggregations: Aggregation[];
    mappedAggregations: MappedAggregations;
    loading: boolean;
    error: ApolloError | undefined;
    materialAggregation: TagsAggregation[];
    fitAggregation: TagsAggregation[];
    tagsAggregation: TagsAggregation[];
}

const initialData = {
    aggregations: [],
    mappedAggregations: new Map(),
    loading: false,
    error: undefined,
    materialAggregation: [],
    fitAggregation: [],
    tagsAggregation: [],
};

export const AggregationQueryContext = React.createContext<AggregationQueryContextType>(initialData);

export const useAggregationsByCategoryId = (id: string) => {
    const { loading, error, data } = useQuery<aggregations, aggregationsVariables>(categoryQuery, {
        variables: {
            idAsString: id,
        },
        ssr: useDeps().env.SSR_GQL,
    });

    return useMemo(() => {
        if (!data?.aggregations?.aggregations) return { ...initialData, loading, error };
        const output = fromAggregationsQuery(data.aggregations.aggregations);
        const mappedAggregations = aggregationsToMap(output);

        const filterAggregations = (attributeCode: string) => {
            return (
                output
                    ?.find((el) => el.attribute_code === attributeCode)
                    ?.options?.map((o) => {
                        return {
                            label: o?.label || '',
                            value: o?.value || '',
                        };
                    }) || []
            );
        };

        const tagsAggregation = filterAggregations('product_tags');
        const materialAggregation = filterAggregations('material');
        const fitAggregation = filterAggregations('filter_body_fit');

        return {
            loading,
            error,
            aggregations: output,
            mappedAggregations,
            tagsAggregation,
            materialAggregation,
            fitAggregation,
        };
    }, [data, error, loading]);
};

export const AggregationQueryProvider: React.FC<{ id: number }> = React.memo((props) => {
    const api: AggregationQueryContextType = useAggregationsByCategoryId(String(props.id));

    return <AggregationQueryContext.Provider value={api}>{props.children}</AggregationQueryContext.Provider>;
});
