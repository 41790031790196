import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useDeps } from 'src/hooks/useDeps';
import { useViewport } from 'src/hooks/useViewport';
import { PAGE_TITLE_ALL_PRODUCTS, PAGE_TITLE_BRAND } from 'src/constants/navigation';

import { Wrapper } from '../Layout';
import { ButtonLink } from '../Button/ButtonLink';
import { Svg } from '../Svg/Svg';
import { RichText } from '../RichText/RichText';

import classes from './NavSub.scss';
import { AlphabeticalNav } from './AlphabeticalNav';
import { AttributesNav } from './AttributesNav';
import { BrandsNav } from './BrandsNav';

type NavSubProps = {
    parentUrl: string;
    parentTitle: string;
    subNavs: NavSubMenu[];
    showSub: boolean;
    toggleList(value);
};

export interface NavSubMenu {
    title: string;
    url: string;
    type: string;
    html: string;
    children: {
        title: string;
        url: string;
        type: string;
        html?: string | null;
    }[];
}

const NavSubMap = {
    alphabetical: AlphabeticalNav,
    brands: BrandsNav,
    attributes: AttributesNav,
};

const getType = (title: string, isMobile: boolean) => {
    switch (title) {
        case PAGE_TITLE_ALL_PRODUCTS:
            return 'alphabetical';
        case PAGE_TITLE_BRAND:
            return isMobile ? 'brands' : 'alphabetical';
        default:
            return 'attributes';
    }
};

export const NavSub: React.FC<NavSubProps> = React.memo(({ subNavs, parentUrl, parentTitle, showSub, toggleList }) => {
    const deps = useDeps();
    const addPrefixToMedia = (content) => {
        let text = content.html;
        if (text && !text.includes('https') && text.includes('media')) {
            text = text.replace('media', `${deps.env.BACKEND}/media`);
        }
        return text;
    };

    const { isMobile } = useViewport();

    const Component = NavSubMap[getType(parentTitle, !isMobile)] || null;

    const withoutBlocks = subNavs.filter((el) => !el.title.includes('Block'));

    const blocks = subNavs.filter((el) => el.title.includes('Block'));

    return (
        <Wrapper
            bg="white"
            element="div"
            className={classnames({
                [classes.navSub]: true,
                [classes.navSubActive]: showSub,
            })}
        >
            <span className={classes.navSubHeader}>
                <button
                    type="button"
                    className={classes.navSubHeaderBack}
                    onClick={() => {
                        toggleList(-1);
                    }}
                >
                    <Svg name="arrow-left" />
                    Back
                </button>
                <h2 className={classes.navSubHeaderTitle}>
                    <Link to={parentUrl}>{parentTitle}</Link>
                </h2>
            </span>
            <Component items={withoutBlocks} />
            {blocks && (
                <div className={classes.navSubBlockWrapper}>
                    {blocks.map((content, i) => {
                        const formattedContent = addPrefixToMedia(content);

                        return (
                            <div key={i} className={classes.navSubBlock}>
                                <RichText content={formattedContent} />
                            </div>
                        );
                    })}
                </div>
            )}
            <ButtonLink to={parentUrl} className={classes.navSubAll}>
                View all {parentTitle}
            </ButtonLink>
        </Wrapper>
    );
});
