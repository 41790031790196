import React, { useState } from 'react';
import { Container, Wrapper } from 'src/components/Layout';
import { Svg } from 'src/components/Svg/Svg';
import { LazyImg } from 'src/hooks/useLazyLoad';
import { TrustPilot } from 'src/components/TrustPilot/TrustPilot';

import classes from './Usps.scss';

type Props = Record<string, unknown>;

export const Usps: React.FC<Props> = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const data = [
        {
            title: 'Free Returns',
            desc: 'For Up To 28 Days!',
            icon: require(`../../../static/img/usp_delivery.svg`),
        },
        {
            title: 'Price Beat Guarantee',
            desc: 'Loved By Over 2 million customers!',
            icon: require(`../../../static/img/usp_promise.svg`),
        },
        {
            title: 'Reviews',
        },
    ];

    const totalCount = data.length;
    const position = (slideIndex * 100) / 4;

    return (
        <Wrapper className={classes.usp} bg="grey" element="section">
            <Container className={classes.uspContainer}>
                <button
                    type="button"
                    disabled={slideIndex === 0}
                    onClick={() => {
                        setSlideIndex(slideIndex - 1);
                    }}
                    className={classes.uspPrev}
                >
                    <Svg name="arrow-left" />
                </button>

                <ul className={classes.uspList} style={{ transform: `translateX(-${position}%)` }}>
                    {data.map((item, index) => {
                        return (
                            <li key={index} className={classes.uspItem}>
                                {item.icon && (
                                    <>
                                        <figure className={classes.uspItemIcon}>
                                            <LazyImg src={item.icon} alt={item.title} />
                                        </figure>
                                        <span>
                                            <b>{item.title}</b> {item.desc}
                                        </span>
                                    </>
                                )}
                                {item.title === 'Reviews' && <TrustPilot smallWidget />}
                            </li>
                        );
                    })}
                </ul>

                <button
                    type="button"
                    disabled={slideIndex === totalCount - 1}
                    onClick={() => {
                        setSlideIndex(slideIndex + 1);
                    }}
                    className={classes.uspNext}
                >
                    <Svg name="arrow-right" />
                </button>
            </Container>
        </Wrapper>
    );
};
