import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { useDeps } from 'src/hooks/useDeps';
import { resolveUrl } from 'src/queries/__generated__/resolveUrl';
import { fireGtm } from 'src/features/analytics/hooks/useGtm';
import { shallowEqual, useSelector } from 'react-redux';
import { AppendState as UserAppendState } from '@wearejh/m2-pwa-user';
import { AppendState } from '@wearejh/m2-pwa-user-edit/lib/user-edit.register';

import urlResolver from '../../queries/urlResolver.graphql';

type Props = {
    enabled: boolean;
    id: string | null;
};

const selector = (s: AppendState<UserAppendState>) => {
    return {
        id: s.user.data?.id,
    };
};

export const GoogleTagManager: React.FC<Props> = (props) => {
    const { id } = useSelector(selector, shallowEqual);
    const [scripts, setScripts] = useState<{ script: string; noscript: string } | null>(null);
    const { pathname } = useLocation();

    const url = pathname.includes('/checkout')
        ? pathname.includes('/checkout/success')
            ? '/checkout/success'
            : '/checkout'
        : pathname.includes('/customer')
        ? '/customer'
        : pathname.includes('/customisation/')
        ? '/customisation'
        : pathname;

    const { data } = useQuery<resolveUrl>(urlResolver, {
        ssr: useDeps().env.SSR_GQL,
        variables: {
            urlKey: url,
        },
    });
    const pageType = data?.urlResolver?.type;

    /**
     * Trigger the scripts to be added once
     */

    useEffect(() => {
        const win = window as any;
        win.dataLayer = win.dataLayer || [];
        win.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });

        setScripts({
            script: 'https://www.googletagmanager.com/gtm.js?id=' + props.id,
            noscript: 'https://www.googletagmanager.com/ns.html?id=' + props.id,
        });
    }, [props.id]);

    useEffect(() => {
        fireGtm({ pageType: pageType, userId: String(id) || 'null' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType]);

    /**
     * Prevent the script from being added immediately - we
     * want time to create the datalayer etc before we add it
     */
    if (!scripts) return null;

    return (
        <Helmet>
            <script src={scripts.script} />
            <noscript>{`
                <iframe src={fallback} height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}>
                    &nbsp;
                </iframe>
            `}</noscript>
        </Helmet>
    );
};
